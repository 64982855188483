import React, {Fragment} from "react";
import {
    useLoaderData,
    useLocation,
    useNavigate,
    useOutletContext,
    useTransition
} from "@remix-run/react";
import type {LoaderFunction, MetaFunction, LinksFunction} from "@remix-run/node";
import {json} from "@remix-run/node";
import type {NewsWidgetProps} from "@bettorsignals/ui";
import {LoadingIndicator, OffersSlider, Signal, Pagination, NewsWidget} from "@bettorsignals/ui";
import type {Signal as SignalType, User, NewsWidget as NewsWidgetItem} from "@bettorsignals/api";
import {CategoryEnum, Collection, isSignal, isUser} from "@bettorsignals/api";
import {CATEGORIES} from "~/constants/Categories.dict";
import {LinkTo} from "~/utils/LinkTo";
import {$api} from "~/api";
import type {TransportError} from "~/api";
import {useLinkTo} from "~/hooks/useLinkTo";
import {isBefore} from "date-fns";
import type {WithOffersOutletContext} from "../__with-offers";
import {Meta} from "@directus/sdk";

interface LoaderData {
    news: NewsWidgetProps["news"];
    signals: Array<SignalType & {author: User; isActive: boolean}>;
    pagesCount: number;
    currentPage: number;
}

export const links: LinksFunction = () => {
    return [{rel: "canonical", href: `${LinkTo.fullPageUrl()}`}];
};

export const loader: LoaderFunction = async ({request}) => {
    const SIGNALS_PER_PAGE = 15;

    const pageUrlParameter = new URL(request.url).searchParams.get("page");
    const currentPage = (pageUrlParameter && Number.parseInt(pageUrlParameter)) || 1;

    const categorySearch = new URL(request.url).searchParams.get("category");
    const category = Object.values(CategoryEnum).includes(categorySearch as CategoryEnum)
        ? (categorySearch as CategoryEnum)
        : CategoryEnum.BestSignals;

    const newsWidgetRequest = $api
        .singleton(Collection.NewsWidget)
        .read({
            fields: [
                "items.collection",
                //previews
                "items.item:previews.title",
                "items.item:previews.slug",
                "items.item:previews.description",
                "items.item:previews.cover",
                //posts
                "items.item:posts.title",
                "items.item:posts.slug",
                "items.item:posts.cover"
            ]
        })
        .catch((error) => {
            console.error(error);
            return null;
        });

    const signalsRequest = $api
        .items(Collection.Signals)
        .readByQuery({
            fields: ["*", "categories.*", "author.*", "images.*"],
            filter: {
                ...(category === CategoryEnum.BestSignals
                    ? {}
                    : {
                          categories: {
                              //@ts-ignore junction collection is not typed. "item" is a PK of "categories".
                              item: {
                                  _in: [category]
                              }
                          }
                      })
            },
            sort: ["-dateExpires"],
            limit: SIGNALS_PER_PAGE, //TODO introduce infinite scroll - https://app.clickup.com/t/2626936/RND-638
            meta: Meta.FILTER_COUNT,
            page: currentPage
        })
        .catch((error: TransportError) => {
            const message =
                error?.response?.errors?.[0]?.message ?? "Error while retrieving signals data";
            const status = error?.response?.status ?? 500;

            throw new Response(message, {
                status
            });
        });

    const [newsWidgetResponse, signalsResponse] = await Promise.all([
        newsWidgetRequest,
        signalsRequest
    ]);

    const linkTo = new LinkTo(process.env.API_URL!);
    const news: LoaderData["news"] =
        newsWidgetResponse?.items?.reduce(
            (accumulator: LoaderData["news"], current: NewsWidgetItem) => {
                switch (current.collection) {
                    case "previews": {
                        return [
                            ...accumulator,
                            {
                                title: current.item.title,
                                sneakPeek: current.item.description,
                                cta: "Read Preview",
                                href: LinkTo.nfl().preview(current.item.slug),
                                imageUrl: linkTo.asset(current.item.cover, {
                                    width: 600
                                })
                            }
                        ];
                    }
                    case "posts": {
                        return [
                            ...accumulator,
                            {
                                title: current.item.title,
                                sneakPeek: undefined,
                                cta: "Read Article",
                                href: LinkTo.blogPostPage(current.item.slug),
                                imageUrl: linkTo.asset(current.item.cover, {
                                    width: 600
                                })
                            }
                        ];
                    }
                    default:
                        return accumulator;
                }
            },
            []
        ) ?? [];

    const pagesCount = Math.ceil(signalsResponse.meta?.filter_count! / SIGNALS_PER_PAGE);

    const signals: LoaderData["signals"] = (signalsResponse?.data ?? [])
        .filter(
            (signal: any): signal is LoaderData["signals"][number] =>
                isSignal(signal) && isUser(signal.author)
        )
        .map((signal) => ({
            ...signal,
            isActive: isBefore(new Date(), new Date(signal.dateExpires))
        }))
        .sort((a, b) => {
            //Keeping expired signals in the "-dateExpires" sort order as it came in response
            if (!a.isActive) return 0;

            //If a's dateExpires is before b's then it goes first
            return isBefore(new Date(a.dateExpires), new Date(b.dateExpires)) ? -1 : 1;
        });

    return json<LoaderData>({
        news,
        signals,
        currentPage,
        pagesCount
    });
};

export const meta: MetaFunction = () => {
    return {
        title: "Betting Tips For Today 📡 | BettorSignals.com",
        description:
            "View our experts' free betting tips, picks, and predictions for today. Covering NFL, NBA, MLB, NHL, Soccer, and E-Sports."
    };
};

//TODO implement categories count - https://app.clickup.com/t/2u0qkuu
export default function HomePage() {
    const {news, signals, currentPage, pagesCount} = useLoaderData<LoaderData>();
    const transition = useTransition();
    const location = useLocation();
    const {linkTo} = useLinkTo();
    const navigate = useNavigate();
    const {selectedCategory, offers, bookmakers} = useOutletContext<WithOffersOutletContext>();

    return (
        <>
            {!location.search && <NewsWidget news={news} />}

            <h1 className="text-2xl font-bold text-gray-900 md:text-3xl">
                {CATEGORIES[selectedCategory].name}
                {selectedCategory !== CategoryEnum.BestSignals && " Signals"}
            </h1>

            {transition.state === "loading" && transition.location.pathname === "/" ? (
                <div className="flex w-full items-center justify-center py-10">
                    <LoadingIndicator />
                </div>
            ) : signals.length > 0 ? (
                signals.map((signal, index, thisArray) => {
                    const {
                        id,
                        title,
                        categories,
                        content,
                        confidence,
                        author,
                        datePublished,
                        dateExpires,
                        defaultOdds,
                        isActive
                    } = signal;

                    const isPreviousSignalActive = index > 0 && thisArray[index - 1].isActive;

                    const shouldRenderExpiredSignalsPrompt =
                        (index === 0 && !isActive) || //if there is no active signals at all
                        (!isActive && isPreviousSignalActive);

                    const shouldRenderOffersSlider = offers.length > 0 && index === 0;

                    return (
                        <Fragment key={id}>
                            {shouldRenderExpiredSignalsPrompt && (
                                <span className="text-center text-sm italic text-gray-400">
                                    Expired Signals
                                </span>
                            )}

                            <Signal
                                isActive={isActive}
                                categories={categories.map((category) => {
                                    const {name, IconOutline} =
                                        //@ts-ignore junction collection is not typed. "item" is a PK of "categories".
                                        CATEGORIES[category.item as CategoryEnum];

                                    return {name, Icon: IconOutline};
                                })}
                                author={{
                                    nickname: author.nickname,
                                    avatarSrc:
                                        author.avatar &&
                                        linkTo.asset(author.avatar, {
                                            width: 100,
                                            height: 100,
                                            quality: 100,
                                            seoFriendlyName: `${author.nickname} avatar`
                                        })
                                }}
                                datePublished={datePublished}
                                dateExpires={dateExpires}
                                confidence={confidence}
                                title={title}
                                content={content}
                                shareUrl={`${LinkTo.fullPageUrl()}${LinkTo.signalPage(id, title)}`}
                                onClick={() => navigate(LinkTo.signalPage(id, title))}
                                href={LinkTo.signalPage(id, title)}
                                odds={defaultOdds}
                                bookmakers={bookmakers.map((bookmaker) => {
                                    return {
                                        url: bookmaker.url,
                                        details: {
                                            name: bookmaker.name,
                                            logoSquare: bookmaker.logoSquare,
                                            logoRectangle: bookmaker.logoRectangle,
                                            colors: bookmaker.colors
                                        }
                                    };
                                })}
                                trackingContext={{
                                    category: categories
                                        .map(
                                            //@ts-ignore junction collection is not typed. "item" is a PK of "categories".
                                            (category) => category.item as CategoryEnum
                                        )
                                        .join(","),
                                    signalTitle: title,
                                    signalId: id,
                                    authorNickname: author.nickname,
                                    authorId: author.id,
                                    odds: defaultOdds || "-",
                                    confidence
                                }}
                            />

                            {shouldRenderOffersSlider && (
                                <div className="block py-6 md:hidden">
                                    <h2 className="pb-1 text-xl font-bold text-gray-900">
                                        Free Bets &amp; Offers
                                    </h2>

                                    <OffersSlider offers={offers} />
                                </div>
                            )}
                        </Fragment>
                    );
                })
            ) : (
                <>
                    <span className="py-10 text-center text-gray-600">
                        Sorry! There are no active signals for given criteria.
                    </span>

                    {offers.length > 0 && (
                        <>
                            <div className="block md:hidden">
                                <OffersSlider offers={offers} />
                            </div>
                        </>
                    )}
                </>
            )}

            <div className="mb-12 md:mb-24">
                <Pagination pagesToDisplay={5} pagesCount={pagesCount} currentPage={currentPage} />
            </div>
        </>
    );
}
